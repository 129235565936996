exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-footer-footer-js": () => import("./../../../src/pages/components/footer/footer.js" /* webpackChunkName: "component---src-pages-components-footer-footer-js" */),
  "component---src-pages-components-navbar-js": () => import("./../../../src/pages/components/navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

